'use client';

import React, { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import cn from 'classnames';

import PasswordViewOffSVG from '@/icons/PasswordViewOffSVG';
import PasswordViewOnSVG from '@/icons/PasswordViewOnSVG';
import { ClassNameProps, SVGProps } from '@/types/common.types';

import { normalizeSpaces } from '@/helpers/transformations.helpers';

import styles from '@/components/Form/FormInput.module.scss';

// =================================================================

type FormInputProps = ClassNameProps & {
  name: string;
  type?: string;
  label: string;
  icon?: React.ComponentType<SVGProps>;
  passwordView?: boolean;
  iconWidth?: number;
  iconHeight?: number;
  normalizeOnBlur?: boolean;
  maxLength?: number;
  errorClassName?: string;
  autoFocus?: boolean;
};

// =================================================================

export const FormInput = (props: FormInputProps) => {
  const {
    name,
    type = 'text',
    label,
    icon: Icon,
    passwordView = false,
    iconWidth = 24,
    iconHeight = 24,
    normalizeOnBlur = false,
    maxLength = 36,
    errorClassName,
    className,
    autoFocus = false,
  } = props;

  const { control } = useFormContext();

  const {
    field: { onBlur, ...fieldProps },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const [isFocused, setIsFocused] = useState(false);
  const [inputType, setInputType] = useState(type);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (normalizeOnBlur) {
      fieldProps.onChange(normalizeSpaces(e.target.value));
    }
    onBlur();
  };

  return (
    <div className={styles.formGroup}>
      <div
        className={cn(className, {
          [styles.formField]: true,
          [styles.focused]: isFocused,
        })}
      >
        {Icon && (
          <label
            htmlFor={name}
            className={cn({
              [styles.label]: true,
              [styles.notEmpty]: fieldProps.value !== '',
              [styles.hasError]: error,
            })}
          >
            <Icon width={iconWidth} height={iconHeight} className={styles.labelIcon} />
          </label>
        )}

        <input
          autoFocus={autoFocus}
          type={inputType}
          id={name}
          className={cn({
            [styles.formInput]: true,
            [styles.hasError]: error,
          })}
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
          maxLength={maxLength}
          placeholder={label}
          {...fieldProps}
        />
        {passwordView && (
          <label
            htmlFor={name}
            className={cn({
              [styles.passwordViewLabel]: true,
              [styles.notEmpty]: fieldProps.value !== '',
              [styles.hasError]: error,
            })}
          >
            {inputType === 'text' ? (
              <PasswordViewOnSVG
                width={iconWidth}
                height={iconHeight}
                className={styles.passwordIcon}
                onClick={() => setInputType('password')}
              />
            ) : (
              <PasswordViewOffSVG
                width={iconWidth}
                height={iconHeight}
                className={styles.passwordIcon}
                onClick={() => setInputType('text')}
              />
            )}
          </label>
        )}
      </div>

      {error && <div className={cn(styles.error, errorClassName)}>{error.message}</div>}
    </div>
  );
};

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  FormInput.displayName = 'FormInput';
}
