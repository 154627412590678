import type { SVGProps } from '@/types/common.types';

const PasswordViewOffSVG = (props: SVGProps) => {
  return (
    <svg viewBox="-2 -2 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15.9526 6.88498C15.7351 6.53998 15.5026 6.21748 15.2626 5.91748C14.9851 5.56498 14.4601 5.53498 14.1451 5.84998L11.8951 8.09998C12.0601 8.59498 12.0901 9.16498 11.9401 9.75748C11.6776 10.815 10.8226 11.67 9.76508 11.9325C9.17258 12.0825 8.60258 12.0525 8.10758 11.8875C8.10758 11.8875 7.03508 12.96 6.26258 13.7325C5.88758 14.1075 6.00758 14.7675 6.51008 14.9625C7.31258 15.27 8.14508 15.4275 9.00008 15.4275C10.3351 15.4275 11.6326 15.0375 12.8176 14.31C14.0251 13.56 15.1126 12.4575 15.9901 11.055C16.7026 9.92248 16.6651 8.01748 15.9526 6.88498Z" />
      <path d="M10.515 7.48498L7.48498 10.515C7.10248 10.125 6.85498 9.58498 6.85498 8.99998C6.85498 7.82248 7.81498 6.85498 8.99998 6.85498C9.58498 6.85498 10.125 7.10248 10.515 7.48498Z" />
      <path d="M13.6875 4.31251L11.145 6.85501C10.5975 6.30001 9.84 5.97001 9 5.97001C7.32 5.97001 5.97 7.32751 5.97 9.00001C5.97 9.84001 6.3075 10.5975 6.855 11.145L4.32 13.6875H4.3125C3.48 13.0125 2.715 12.15 2.0625 11.13C1.3125 9.95251 1.3125 8.04001 2.0625 6.86251C2.9325 5.49751 3.9975 4.42501 5.1825 3.69001C6.3675 2.97001 7.665 2.57251 9 2.57251C10.6725 2.57251 12.2925 3.18751 13.6875 4.31251Z" />
      <path d="M11.1451 9.00006C11.1451 10.1776 10.1851 11.1451 9.00006 11.1451C8.95506 11.1451 8.91756 11.1451 8.87256 11.1301L11.1301 8.87256C11.1451 8.91756 11.1451 8.95506 11.1451 9.00006Z" />
      <path d="M16.3277 1.67241C16.1027 1.44741 15.7352 1.44741 15.5102 1.67241L1.67266 15.5174C1.44766 15.7424 1.44766 16.1099 1.67266 16.3349C1.78516 16.4399 1.92766 16.4999 2.07766 16.4999C2.22766 16.4999 2.37016 16.4399 2.48266 16.3274L16.3277 2.48241C16.5602 2.25741 16.5602 1.89741 16.3277 1.67241Z" />
    </svg>
  );
};

export default PasswordViewOffSVG;
