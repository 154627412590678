import type { SVGProps } from '@/types/common.types';

const SearchSVG = (props: SVGProps) => {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9469 13.7714L19.7551 18.5796C20.0816 18.902 20.0816 19.4327 19.7551 19.7551C19.4286 20.0816 18.902 20.0816 18.5755 19.7551L13.7673 14.951C12.3143 16.1429 10.4571 16.8571 8.42857 16.8571C3.77143 16.8571 0 13.0816 0 8.42857C0 3.77551 3.77143 0 8.42857 0C13.0816 0 16.8571 3.77551 16.8571 8.42857C16.8571 10.4571 16.1388 12.3184 14.9469 13.7714ZM1.67347 8.42857C1.67347 12.151 4.70204 15.1796 8.42857 15.1796C12.151 15.1796 15.1837 12.151 15.1837 8.42857C15.1837 4.70612 12.151 1.67347 8.42857 1.67347C4.70204 1.67347 1.67347 4.70612 1.67347 8.42857Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SearchSVG;
