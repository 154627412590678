import type { SVGProps } from '@/types/common.types';

const SelectScrollerSVG = (props: SVGProps) => (
  <svg viewBox="0 0 6 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M3 0L6 4H0L3 0Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3 10L6 6H0L3 10Z" />
  </svg>
);

export default SelectScrollerSVG;
