'use client';

import React, { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import cn from 'classnames';

import { SVGProps } from '@/types/common.types';

import styles from '@/components/Form/FormInput.module.scss';

// =================================================================

type NumberInputProps = NumberFormatProps & {
  name: string;
  icon?: React.ComponentType<SVGProps>;
  className?: string;
  errorClassName?: string;
  isPhoneInput?: boolean;
};

// =================================================================

export const NumberInput: React.FC<NumberInputProps> = (props: NumberInputProps) => {
  const { name, icon: Icon, className, errorClassName, isPhoneInput, ...rest } = props;

  const { control } = useFormContext();

  const {
    field: { ref, onBlur, ...fieldProps },
    fieldState: { error },
  } = useController({ name, control });

  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = () => {
    setIsFocused(false);
    onBlur();
  };

  const handleFocus = () => setIsFocused(true);

  return (
    <div className={styles.formGroup}>
      <div
        className={cn({
          [styles.formField]: true,
          [styles.focused]: isFocused,
        })}
      >
        {Icon && (
          <label
            htmlFor={name}
            className={cn({
              [styles.phoneLabel]: isPhoneInput,
              [styles.label]: !isPhoneInput,
              [styles.notEmpty]: fieldProps.value !== '',
              [styles.hasError]: error,
            })}
          >
            <Icon width="1.8rem" height="1.8rem" className={styles.labelIcon} />
            {isPhoneInput && (
              <div
                className={cn({
                  [styles.iconBorder]: true,
                })}
              >
                +998
              </div>
            )}
          </label>
        )}

        <NumberFormat
          id={name}
          className={cn(className, {
            [styles.formInput]: true,
            [styles.hasError]: error,
            [styles.focused]: isFocused,
          })}
          onFocus={handleFocus}
          {...fieldProps}
          {...rest}
          onBlur={handleBlur}
        />
      </div>

      {error && <div className={cn(styles.error, errorClassName)}>{error.message}</div>}
    </div>
  );
};
// =================================================================

if (process.env.NODE_ENV !== 'production') {
  NumberInput.displayName = 'NumberInput';
}
