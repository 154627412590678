'use client';

import { useController, useFormContext } from 'react-hook-form';
import { Props } from 'react-select';

import cn from 'classnames';

import styles from '@/components/Form/FormSelect.module.scss';
import { Select } from '@/components/Form/Select';

import { useMediaQuery } from '@/contexts/MediaQueryContext';

// =================================================================

type FormSelectProps = Props & {
  label?: string;
  initialValue?: Props['options'] | unknown;
  name: string;
};

// =================================================================

export const FormSelect = (props: FormSelectProps) => {
  const {
    isMulti = false,
    name,
    label,
    placeholder,
    initialValue,
    options,
    isSearchable,
    noOptionsMessage,
    isDisabled,
    className,
  } = props;

  const matches = useMediaQuery();

  const { control } = useFormContext();

  const {
    field: { onChange, disabled, value },
  } = useController({
    name,
    control,
  });

  const handleChange: Props['onChange'] = value => {
    if (value) {
      onChange(value);
    } else {
      onChange(initialValue);
    }
  };

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={cn({
          [styles.label]: true,
          [styles.isDisabled]: isDisabled || disabled,
        })}
      >
        {label}
      </label>
      <Select
        isMulti={isMulti}
        name={name}
        placeholder={placeholder}
        value={value}
        inputId={name}
        options={options}
        isSearchable={matches && matches.medium ? isSearchable : false}
        noOptionsMessage={noOptionsMessage}
        isDisabled={isDisabled}
        onChange={handleChange}
        tabSelectsValue={false}
        isClearable
      />
    </div>
  );
};
