'use client';

import { forwardRef, useRef, useCallback } from 'react';
import { useEffectOnce } from 'react-use';

import clsx from 'clsx';

import type { ChildrenProps } from '@/types/common.types';

import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';

import { FadeTransition } from '@/components/Animations';
import { Portal } from '@/components/Portal';

import styles from './Overlay.module.scss';

// =================================================================

export interface OverlayProps extends ChildrenProps {
  isOpen: boolean;
  onClose: () => void;
  shouldUseAnimation?: boolean;
  shouldUsePortal?: boolean;
  hasBackdrop?: boolean;
  canEscapeKeyClose?: boolean;
  canOutsideClickClose?: boolean;
  backdropClassName?: string;
}

// =================================================================

export const Overlay = forwardRef<HTMLDivElement, OverlayProps>((props, ref) => {
  const {
    isOpen,
    onClose,
    shouldUseAnimation = true,
    shouldUsePortal = true,
    hasBackdrop = true,
    canEscapeKeyClose = true,
    canOutsideClickClose = true,
    backdropClassName,
    children,
  } = props;

  const onCloseRef = useRef<() => void>();
  if (!onCloseRef.current) {
    onCloseRef.current = onClose;
  }

  const handleClick = useCallback(() => {
    if (canOutsideClickClose) {
      onCloseRef.current!();
    }
  }, [canOutsideClickClose]);

  useLockBodyScroll({ isLocked: isOpen });

  useEffectOnce(() => {
    if (canEscapeKeyClose) {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.code === 'Escape') {
          onCloseRef.current!();
        }
      };

      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  });

  const modal = (
    <div ref={ref} className={styles.overlay}>
      <div
        className={clsx({
          [styles.backdrop]: true,
          backdropClassName,
          [styles.background]: hasBackdrop,
        })}
        onClick={handleClick}
      />
      {children}
    </div>
  );

  const overlay = shouldUseAnimation ? (
    <FadeTransition inProp={isOpen}>{modal}</FadeTransition>
  ) : (
    modal
  );

  if (!isOpen && !shouldUseAnimation) {
    return null;
  }

  if (shouldUsePortal) {
    return <Portal>{overlay}</Portal>;
  }

  return overlay;
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  Overlay.displayName = 'Overlay';
}
