import { memo, ReactNode, type ReactSVGElement } from 'react';

import clsx from 'clsx';

import CloseSVG from '@/icons/CloseSVG';
import { ChildrenProps, ClassNameProps, SVGProps } from '@/types/common.types';

import { IconButton } from '@/components/Buttons/IconButton';

import styles from './Modal.module.scss';

// =================================================================

interface ModalHeaderProps extends Partial<ChildrenProps>, ClassNameProps {
  onClose?: () => void;
  isCloseButtonShown?: boolean;
  icon?: ReactNode;
}

// =================================================================

export const ModalHeader = memo((props: ModalHeaderProps) => {
  const { onClose, icon, children, className, isCloseButtonShown = true } = props;

  return (
    <div
      className={clsx(
        {
          [styles.modalHeader]: true,
          [styles.safetyPaddingRight]: isCloseButtonShown,
        },
        className,
      )}
    >
      {icon && <div className={styles.modalIconWrapper}>{icon}</div>}
      {children}

      {isCloseButtonShown && (
        <IconButton
          as="button"
          title={'Закрыть'}
          icon={CloseSVG}
          className={styles.modalCloseButton}
          iconClassName={styles.closeButtonIcon}
          onClick={onClose}
          iconHeight={25}
          iconWidth={25}
        />
      )}
    </div>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  ModalHeader.displayName = 'ModalHeader';
}
