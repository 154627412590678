import type { SVGProps } from '@/types/common.types';

const PasswordViewOnSVG = (props: SVGProps) => {
  return (
    <svg viewBox="-2 -2 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15.9375 6.86251C14.205 4.14001 11.67 2.57251 9 2.57251C7.665 2.57251 6.3675 2.96251 5.1825 3.69001C3.9975 4.42501 2.9325 5.49751 2.0625 6.86251C1.3125 8.04001 1.3125 9.95251 2.0625 11.13C3.795 13.86 6.33 15.42 9 15.42C10.335 15.42 11.6325 15.03 12.8175 14.3025C14.0025 13.5675 15.0675 12.495 15.9375 11.13C16.6875 9.96001 16.6875 8.04001 15.9375 6.86251ZM9 12.03C7.32 12.03 5.97 10.6725 5.97 9.00001C5.97 7.32751 7.32 5.97001 9 5.97001C10.68 5.97001 12.03 7.32751 12.03 9.00001C12.03 10.6725 10.68 12.03 9 12.03Z" />
      <path d="M9.00005 6.85498C7.82255 6.85498 6.86255 7.81498 6.86255 8.99998C6.86255 10.1775 7.82255 11.1375 9.00005 11.1375C10.1775 11.1375 11.145 10.1775 11.145 8.99998C11.145 7.82248 10.1775 6.85498 9.00005 6.85498Z" />
    </svg>
  );
};

export default PasswordViewOnSVG;
