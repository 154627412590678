'use client';

import { useState, useEffect, useContext, createContext } from 'react';

import { breakpoints } from '@/app.config';
import type { ChildrenProps, WindowSizes } from '@/types/common.types';

// =================================================================

type MediaQueryState = {
  [K in WindowSizes]: boolean;
};

const MediaQueryContext = createContext<MediaQueryState | undefined>(undefined);

// =================================================================

export const MediaQueryProvider = (props: ChildrenProps) => {
  const { children } = props;

  const [matches, setMatches] = useState<MediaQueryState | undefined>(undefined);

  useEffect(() => {
    const sizes = Object.keys(breakpoints) as WindowSizes[];
    const mediaQueryLists: Partial<Record<WindowSizes, MediaQueryList>> = {};
    const matches: Partial<MediaQueryState> = {};

    sizes.forEach(size => {
      mediaQueryLists[size] = window.matchMedia(breakpoints[size]);
      matches[size] = mediaQueryLists[size]?.matches;
    });

    setMatches(matches as MediaQueryState);

    const handleMatchesUpdate = () => {
      const updateMatches: Partial<MediaQueryState> = {};
      sizes.forEach(size => (updateMatches[size] = mediaQueryLists[size]?.matches));
      setMatches(updateMatches as MediaQueryState);
    };

    sizes.forEach(size => {
      mediaQueryLists[size]?.addListener(handleMatchesUpdate);
    });

    return () => sizes.forEach(size => mediaQueryLists[size]?.removeListener(handleMatchesUpdate));
  }, []);

  return <MediaQueryContext.Provider value={matches}>{children}</MediaQueryContext.Provider>;
};

// =================================================================

export const useMediaQuery = () => {
  const context = useContext(MediaQueryContext);

  if (typeof context !== 'undefined' && !context) {
    throw new Error('useMediaQuery should be used within MediaQueryProvider');
  }

  return context;
};
