import type { SVGProps } from '@/types/common.types';

const CheckmarkSVG = (props: SVGProps) => {
  return (
    <svg
      width="15"
      height="10"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2528 1.86718C13.4091 2.02343 13.4872 2.21316 13.4872 2.43637C13.4872 2.65959 13.4091 2.84932 13.2528 3.00557L7.19257 9.06582L6.05417 10.2042C5.89792 10.3605 5.70819 10.4386 5.48498 10.4386C5.26176 10.4386 5.07203 10.3605 4.91578 10.2042L3.77739 9.06582L0.747253 6.03568C0.591003 5.87943 0.512878 5.6897 0.512878 5.46649C0.512878 5.24328 0.591003 5.05354 0.747253 4.89729L1.88565 3.7589C2.0419 3.60265 2.23163 3.52452 2.45484 3.52452C2.67806 3.52452 2.86779 3.60265 3.02404 3.7589L5.48498 6.22821L10.976 0.72876C11.1323 0.57251 11.322 0.494385 11.5452 0.494385C11.7684 0.494385 11.9582 0.57251 12.1144 0.72876L13.2528 1.86718Z"
        fill="black"
      />
    </svg>
  );
};

export default CheckmarkSVG;
