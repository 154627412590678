'use client';

import type { ReactNode } from 'react';
import React from 'react';
import ReactSelect, { components, DropdownIndicatorProps, Props, StylesConfig } from 'react-select';
import makeAnimated from 'react-select/animated';

import cn from 'classnames';
import { noop } from 'lodash';

import SearchSVG from '@/icons/SearchSVG';
import ScrollerSVG from '@/icons/SelectScrollerSVG';
import stylesVariables from '@/styles/variables.module.scss';

import styles from './Select.module.scss';

type SelectProps = Props & {
  searchInputToggler?: () => void;
  searchBar?: ReactNode;
  isSearchOpen?: boolean;
};

type SearchButtonProps = {
  searchInputToggler: () => void;
  isSearchOpen: boolean;
};

// =================================================================

const customStyles = (withSearchIcon: boolean): StylesConfig => ({
  container: (base, { isDisabled }) => ({
    ...base,
    opacity: isDisabled ? 0.5 : 1,
  }),
  control: (base, { isFocused }) => ({
    ...base,
    fontSize: '0.9375rem',
    backgroundColor: stylesVariables.darkColor3,
    borderColor: isFocused ? 'none' : stylesVariables.darkColor3,
    boxShadow: 'none',
    position: 'relative',
    paddingRight: withSearchIcon ? '2.5rem' : '',
    '&:hover': {
      borderColor: 'none',
    },
  }),
  placeholder: base => ({
    ...base,
    color: stylesVariables.lightColor1,
  }),
  input: base => ({
    ...base,
    color: stylesVariables.lightColor1,
  }),
  menu: base => ({
    ...base,
    backgroundColor: stylesVariables.darkColor4,
    boxShadow: stylesVariables.imageBoxShadow,
    zIndex: 9,
  }),
  menuList: base => ({
    ...base,
    padding: 0,
    borderRadius: 4,
  }),
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    cursor: 'pointer',
    fontSize: '0.9375rem',
    color: isFocused || isSelected ? stylesVariables.lightColor1 : stylesVariables.lightColor1A50,
    backgroundColor:
      isFocused || isSelected ? stylesVariables.darkColor3 : stylesVariables.darkColor4,
    transition: 'color 0.15s, background-color 0.15s',
    '&:active': {
      color: stylesVariables.lightColor1,
      backgroundColor: stylesVariables.darkColor3,
    },
  }),
  noOptionsMessage: base => ({
    ...base,
    fontSize: '0.9rem',
    color: stylesVariables.lightColor1,
  }),
  singleValue: base => ({
    ...base,
    color: stylesVariables.lightColor1,
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: 'none',
    borderRadius: 4,
    overflow: 'hidden',
  }),
  multiValueLabel: base => ({
    ...base,
    fontWeight: 600,
    color: stylesVariables.darkColor1,
    backgroundColor: stylesVariables.signatureColor,
    borderRadius: 'none',
  }),
  multiValueRemove: base => ({
    ...base,
    cursor: 'pointer',
    color: stylesVariables.lightColor1,
    backgroundColor: stylesVariables.darkColor1,
    borderRadius: 'none',
    transition: 'color 0.15s',
    '&:hover': {
      color: stylesVariables.dangerColor,
      backgroundColor: stylesVariables.darkColor1,
    },
  }),
  indicatorSeparator: base => ({
    ...base,
    backgroundColor: stylesVariables.lightColor1A50,
    marginTop: 10,
    marginBottom: 10,
    visibility: withSearchIcon ? 'hidden' : 'visible',
  }),
  clearIndicator: base => ({
    ...base,
    cursor: 'pointer',
    color: stylesVariables.lightColor1A50,
    '&:hover': {
      color: stylesVariables.lightColor1,
    },
  }),
  dropdownIndicator: (base, { selectProps }) => {
    const isDisabled = Array.isArray(selectProps.value) && selectProps.value?.length === 2;
    return {
      ...base,
      cursor: isDisabled ? 'auto' : 'pointer',
      color: isDisabled ? stylesVariables.lightColor1A20 : stylesVariables.lightColor1A50,
      '&:hover': {
        color: isDisabled ? stylesVariables.lightColor1A20 : stylesVariables.lightColor1,
      },
    };
  },
});

// =================================================================

const animatedComponents = makeAnimated();

const DropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <ScrollerSVG width={10} height={14} />
  </components.DropdownIndicator>
);

// =================================================================

const SearchButton = ({ searchInputToggler, isSearchOpen }: SearchButtonProps) => {
  const buttonClassNames = cn({
    [styles.searchButton]: true,
    [styles.active]: isSearchOpen,
  });

  return (
    <button
      autoFocus={true}
      className={buttonClassNames}
      onClick={e => {
        e.stopPropagation();
        searchInputToggler();
      }}
    >
      <SearchSVG width={14} height={14} />
    </button>
  );
};

// =================================================================

export const Select = (props: SelectProps) => {
  const {
    isMulti,
    name,
    instanceId,
    placeholder,
    value,
    inputId,
    options,
    isSearchable,
    noOptionsMessage,
    isDisabled,
    onChange,
    tabSelectsValue,
    isClearable,
    searchBar,
    searchInputToggler = noop,
    isSearchOpen,
  } = props;

  const withSearchButton = !!searchBar;

  return (
    <React.Fragment>
      <div className={styles.selectWrapper}>
        <ReactSelect
          isMulti={isMulti}
          instanceId={instanceId}
          name={name}
          placeholder={placeholder}
          value={value}
          inputId={inputId}
          options={options}
          isSearchable={isSearchable}
          noOptionsMessage={noOptionsMessage}
          isDisabled={isDisabled}
          onChange={onChange}
          tabSelectsValue={tabSelectsValue}
          isClearable={isClearable}
          styles={customStyles(withSearchButton)}
          components={{ ...animatedComponents, DropdownIndicator }}
        />
        {withSearchButton && (
          <SearchButton isSearchOpen={!!isSearchOpen} searchInputToggler={searchInputToggler} />
        )}
      </div>
      {searchBar}
    </React.Fragment>
  );
};
