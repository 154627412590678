import { FormHTMLAttributes } from 'react';

import cn from 'classnames';

import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import styles from '@/components/Form/Form.module.scss';
import { FormCheckbox } from '@/components/Form/FormCheckbox';
import { FormInput } from '@/components/Form/FormInput';
import { FormSelect } from '@/components/Form/FormSelect';
import { NumberInput } from '@/components/Form/NumberInput';

// =================================================================

type FormBaseType = ClassNameProps & ChildrenProps;

type AlertStatusType = 'warning' | 'error';

type AlertPanelAlign = 'flexBetween' | 'flexEnd';

export type FormAlertType = ClassNameProps & {
  status?: {
    type: AlertStatusType;
    message: string;
  };
};

type FormPanelType = ChildrenProps & {
  align?: AlertPanelAlign;
};

type FormProps = FormHTMLAttributes<HTMLFormElement> & {
  isSubmitting?: boolean;
};

// =================================================================

const FormHeader = (props: FormBaseType) => {
  const { children, className } = props;
  return <div className={cn(styles.formHeader, className)}>{children}</div>;
};

// =================================================================

const FormTitle = (props: FormBaseType) => {
  const { children, className } = props;
  return <h1 className={cn(styles.formTitle, className)}>{children}</h1>;
};

// =================================================================

const FormAlert = (props: FormAlertType) => {
  const { status, className } = props;

  if (!status) return null;

  return (
    <div className={cn(styles.formAlert, styles[status.type], className)}>{status.message}</div>
  );
};

// =================================================================

const FormBody = (props: FormBaseType) => {
  const { children, className } = props;
  return <div className={cn(styles.formBody, className)}>{children}</div>;
};

// =================================================================

const FormPanel = (props: FormPanelType) => {
  const { align = 'flexBetween', children } = props;
  return <div className={cn(styles.formPanel, styles[align])}>{children}</div>;
};

// =================================================================

const FormFooter = (props: FormBaseType) => {
  const { children, className } = props;
  return <div className={cn(styles.formFooter, className)}>{children}</div>;
};

// =================================================================

export const Form = (props: FormProps) => {
  const {
    autoComplete = 'off',
    noValidate = true,
    children,
    isSubmitting,
    className,
    ...rest
  } = props;

  return (
    <form
      autoComplete={autoComplete}
      noValidate={noValidate}
      className={cn(
        {
          [styles.form]: true,
          [styles.inactive]: isSubmitting,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </form>
  );
};

// =================================================================

Form.Header = FormHeader;
Form.Title = FormTitle;
Form.Alert = FormAlert;
Form.Body = FormBody;
Form.Panel = FormPanel;
Form.Footer = FormFooter;
Form.Input = FormInput;
Form.NumberInput = NumberInput;
Form.Checkbox = FormCheckbox;
Form.Select = FormSelect;

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  Form.displayName = 'Form';
}
